import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

// Connects to data-controller="swiper"
export default class extends Controller {
  connect() {
    this.heroSwiper();
    this.bestSwiper();
    this.featuredSwiper();
  }

  heroSwiper() {
    if ($(".swiper-hero .swiper-slide").length == 1) {
      $(".swiper-hero-pagination").addClass("d-none");
      $(".show-index").addClass("d-none");
    }

    new Swiper(".swiper-hero", {
      modules: [Autoplay, Pagination],
      loop: true,
      effect: "fade",
      autoplay: {
        delay: 8000,
        waitForTransition: true,
        disableOnInteraction: false,
      },
      // pagination: {
      //   el: ".swiper-hero-pagination",
      //   type: "bullets",
      // },
      // If we need pagination
      pagination: {
        el: ".swiper-hero-pagination",
        clickable: true,
        renderBullet: function (index, className) {
          return (
            '<span class="' +
            className +
            '">' +
            '<svg class="fp-arc-loader" width="23" height="23" viewBox="0 0 23 23">' +
            '<circle class="path" cx="11" cy="11" r="10" fill="none" transform="rotate(-90 11 11)" stroke="#FFF"' +
            'stroke-opacity="1" stroke-width="1px"></circle>' +
            '<circle class="circle-inner" cx="11" cy="11" r="5" fill="#E9DFDF"></circle>' +
            "</svg></span>"
          );
        },
      },
    });
  }

  bestSwiper() {
    new Swiper(".best_sales_slider", {
      modules: [Navigation, Pagination],
      slideClass: "card",
      slidesPerView: 1,
      spaceBetween: 27,
      loop: true,
      setWrapperSize: true,
      observer: true,
      observeParents: true,
      navigation: {
        nextEl: ".swiper-arrow-next",
        prevEl: ".swiper-arrow-prev",
      },
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
        },
        // when window width is >= 480px
        768: {
          slidesPerView: 3,
        },
        // when window width is >= 640px
        992: {
          slidesPerView: 4,
        },
      },
    });
  }

  featuredSwiper() {
    new Swiper(".swiper_featured", {
      modules: [Navigation, Pagination],
      spaceBetween: 30,
      slidesPerView: 1,
      pagination: {
        el: ".swiper_featured-pagination",
        type: "bullets",
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
          noSwiping: true,
        },
      },
    });
  }
}
